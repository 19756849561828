/* eslint-disable react/no-multi-comp */
export function RadioIcon() {

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="8.5" cy="8.5" r="7.75" stroke="#212121" strokeWidth="1.5" />
    </svg>
  );

}

export function RadioCheckedIcon() {

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="7.75" stroke="var(--primary)" strokeWidth="1.5" />
      <circle cx="8.5" cy="8.5" r="3.5" fill="var(--primary)" />
    </svg>
  );

}
