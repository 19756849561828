import { useIsDesktop } from 'contexts/profile';
import { BottomDrawer } from 'components/shared/BottomDrawer';
import { PopUp } from 'components/shared/PopUp';
import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Clickable } from 'phoenix-components';
import { RadioCheckedIcon, RadioIcon } from 'components/shared/Icons/radio';
import { ColorCheck } from 'components/shared/Icons/theme';
import styles from './AddButton.module.css';

export default function VariantSelection({
  product,
  onClose,
  onAdd,
  shop,
}) {

  const isDesktop = useIsDesktop();

  const Component = isDesktop ? PopUp : BottomDrawer;
  const { variants = [], colors = [] } = product;
  const [variant, setVariant] = useState(variants.length ? variants[0]?._id : null);
  const [color, setColor] = useState(colors?.length ? colors[0]?.hex : null);

  const onAddToCart = (e) => {
    e.stopPropagation();
    onAdd(color, variant);
  };

  return (
    <Component
      title="Select Variant"
      onClose={onClose}
      closeButton={true}
    >
      <div className={styles.variantContainer}>
        {variants.length > 0 && (
          <div className={styles.variantList}>
            <div className={styles.key}>
              {variants[0]?.info?.name}
            </div>
            <div className={styles.variants}>
              {variants.map((x, i) => (
                <Clickable
                  key={i}
                  onClick={(e) => {

                    e.stopPropagation();
                    setVariant(x._id);
                  
                  }}
                  className={cx(styles.variant, {
                    [styles.selected]: x._id === variant,
                  })}
                >
                  <div className={styles.radio}>
                    {x._id === variant ? (
                      <RadioCheckedIcon />
                    ) : (
                      <RadioIcon />
                    )}
                    <span className={styles.variantName}>
                      {x?.info?.value}
                    </span>
                  </div>
                  <div className={styles.price}>
                    {shop.currency}
                    {' '}
                    {x.discountedAmount}
                  </div>
                </Clickable>
              ))}
            </div>
          </div>
        )}
        {colors.length > 0 && (
          <div className={styles.colorBox}>
            {colors.map((c, i) => (
              <Clickable
                key={i}
                onClick={(e) => {

                  e.stopPropagation();
                  setColor(c.hex);
                
                }}
              >
                <div style={{ backgroundColor: c.hex }} className={styles.color}>
                  {c.hex === color && (
                    <div className={styles.check}>
                      <ColorCheck />
                    </div>
                  )}
                </div>
              </Clickable>
            ))}
          </div>
        )}
        <div className={styles.buttons}>
          <Button label="Cancel" onClick={onClose} primary={false} size="large" fullWidth />
          <Button
            label="ADD TO CART"
            onClick={onAddToCart}
            primary
            size="large"
            fullWidth
          />
        </div>
      </div>
    </Component>
  );

}

VariantSelection.propTypes = {
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  shop: PropTypes.object.isRequired,
};
