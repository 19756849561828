import PropTypes from 'prop-types';
import { useLazyProduct } from 'hooks/product';
import { useLocalCart, useLocalCartProductCount, useRefreshLocalCart } from 'contexts/cart';
import { Clickable } from 'phoenix-components';
import { MinusIcon, PlusIcon } from 'components/shared/Icons/theme';
import SnackBar from 'ui/services/snackbar';
import Loader from 'ui/services/loader';
import Api from 'ui/api';
import { addToCart, deleteCartItem, updateQuantity } from 'ui/api/cart';
import { useState } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import { useLanguageLoader } from 'contexts/language';
import VariantSelection from 'components/store/common/VariantSelection';
import { useRouter } from 'next/router';
import { useIsCustomDomain, useIsVisitor, useRefreshProfile, useShowLogin } from 'contexts/profile';
import styles from './AddButton.module.css';
import { emitEvent, EVENT_TYPES } from 'ui/lib/pixels';
import ShopUtils from 'ui/lib/shopUtils';
import { getQuantity, rulesCheckFromProps } from 'components/store/utils';

export default function AddButton({
  product,
  shop,
  themeName,
}) {

  const loadProduct = useLazyProduct(product);
  const cartCount = useLocalCartProductCount(product._id);
  const cart = useLocalCart();
  const refresh = useRefreshLocalCart();
  const [open, setOpen] = useState(null);
  const isVisitor = useIsVisitor();
  const showLogin = useShowLogin(shop);
  const lang = useLanguageLoader();
  const refreshProfile = useRefreshProfile();
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  const isDirectLogin = ShopUtils.isDirectCheckout(shop);

  const disabled = product?.status === 'out of stock';
  const { products = [] } = cart;
  const item = products.find(x => x.skuId === product._id);
  const count = item?.quantity || 0;

  const quantitySchema = {
    enabled: product?.quantity?.enabled,
    type: product?.quantity?.type,
    minQuantity: product?.quantity?.minQuantity,
    maxQuantity: product?.quantity?.maxQuantity,
    group: product?.quantity?.group,
  };

  const type = get(quantitySchema, 'type', 'infinite');

  const isGroup = type === 'finite' && quantitySchema.group > 10;

  const onClick = () => {

    router.push({ pathname: isCustomDomain ? `/${product.slug}` : `/${product.shopSlug}/${product.slug}` });

  };

  const checkLogin = async () => {

    if (isVisitor) {
      if (isDirectLogin) {
        try {
          Loader.show();
          await Api.loginAsGuest();
          await refreshProfile();
          refresh();
          return false;
        } catch (e) {
          return true;
        } finally {
          Loader.hide();
        }
      }
      showLogin();
      return true;
    }
    return false;
  };

  const rulesToCheckAction = () => rulesCheckFromProps(count, item, quantitySchema);

  const getQuantityFromProps = (isDelete) => getQuantity(count, quantitySchema, isDelete);

  const onAdd = async (e) => {

    e.stopPropagation();
    if (await checkLogin()) {
      return;
    }

    if (isGroup) {
      onClick();
      return;
    }

    try {
      Loader.show();
      const item = await loadProduct();
      if (!item) {
        return;
      }
      if (!item.multiVariant && item.colors.length === 0) {
        if (!rulesToCheckAction()) {
          const msg = `maximum quantity is ${quantitySchema.maxQuantity}`;
          SnackBar.showError(msg);
          return;
        }
        await addToCart(shop._id, {
          color: null,
          note: '',
          quantity: count + getQuantityFromProps(),
          variantId: item.variants[0]._id,
        });
        emitEvent(EVENT_TYPES.ADD_TO_CART);
        refresh();
        return;
      }
      setOpen(item);

    } catch (e) {

      SnackBar.showError(e);

    } finally {

      Loader.hide();

    }

  };

  const onAddToCart = async (color, variantId) => {

    if (await checkLogin()) {
      return;
    }

    try {
      await addToCart(shop._id, {
        color,
        note: '',
        quantity: count + getQuantityFromProps(),
        variantId,
      });
      emitEvent(EVENT_TYPES.ADD_TO_CART);
      refresh();
    } catch (e) {
      SnackBar.showError(e);
    } finally {
      setOpen(null);
      Loader.hide();
    }

  };

  const onDelete = async (e) => {
    e.stopPropagation();
    if (await checkLogin()) {
      return;
    }
    const { products = [] } = cart;
    const item = products.find(x => x.skuId === product._id);
    if (!item) {

      refresh();
      return;

    }
    try {

      Loader.show();
      if (item.quantity > 1) {

        await updateQuantity(shop._id, item._id, {
          quantity: item.quantity - getQuantityFromProps(true),
        });

      } else {

        await deleteCartItem(shop._id, item._id);

      }
      refresh();

    } catch (e) {

      SnackBar.showError(e);

    } finally {

      Loader.hide();

    }

  };

  return (
    <div
      className={cx(styles.addButton, styles[themeName], { [styles.disabled]: disabled })}
      title={lang('buttons.addToCart')}
    >
      {cartCount === 0 || (themeName === 'salvia' || themeName === 'lupine') ? (
        <Clickable onClick={onAdd} className={cx(styles.add)}>
          <span>
            {disabled ? lang('buttons.soldOut') : lang(themeName === 'salvia' ? 'buttons.addToBag' :
              'buttons.addToCart')}
          </span>
          {!disabled && <PlusIcon/>}
        </Clickable>
      ) : (
        <div className={styles.add}>
          <Clickable onClick={onDelete} className={styles.minus}>
            <MinusIcon/>
          </Clickable>
          <span>{cartCount}</span>
          <Clickable onClick={onAdd} className={styles.addIcon}>
            <PlusIcon/>
          </Clickable>
        </div>
      )}
      {open && (
        <VariantSelection
          shop={shop}
          product={open}
          onAdd={onAddToCart}
          onClose={(e) => {
            e.stopPropagation();
            setOpen(null);
          }}
        />
      )}
    </div>
  );

}

AddButton.propTypes = {
  shop: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  themeName: PropTypes.string,
};

AddButton.defaultProps = {
  themeName: 'default',
};
