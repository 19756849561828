import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@mui/material';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import {
  filterIcon, CloseIcon,
} from 'components/shared/Icons/Filters';
import styles from './PopUp.module.css';

export function PopUp({
  title,
  children,
  onClose,
  classes,
  closeButton,
}) {

  const isFilters = title === 'Filters';
  return (
    <Dialog
      PaperProps={{
        classes: {
          root: styles.paper,
        }
      }}
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent className={cx(styles.overflow, classes.container)}>
        {(title || closeButton) && !isFilters && (
          <div className={styles.row}>
            <div className={cx(styles.heading, classes.heading)}>{title}</div>
            {closeButton && (
              <Clickable onClick={onClose}>
                <CloseIcon />
              </Clickable>
            )}
            {closeButton}
          </div>
        )}
        {isFilters && (
          <div className={styles.row}>
            <div className={cx(styles.heading, classes.heading)}>{title}</div>
            <div className={cx(styles.fIcon)}>{filterIcon()}</div>
          </div>
        )}
        <div className={cx(styles.container, classes.container)}>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );

}

PopUp.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node).isRequired
  ]).isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  closeButton: PropTypes.bool,
};

PopUp.defaultProps = {
  title: null,
  onClose: null,
  classes: {},
  closeButton: false,
};
